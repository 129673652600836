.horizontal-scroll-calendar {
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.days-container {
  display: inline-flex;
}

.day-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.circle-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
}

.circle-outline::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(45deg, #7b9fe4 30%, #a762f6 60%, #f57dc6 90%);
  background-clip: padding-box;
  padding: 2px;
  position: absolute;
  z-index: -1;
}

.circle-outline::after {
  content: "";
  display: block;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background-color: white;
  position: absolute;
  z-index: -1;
}
.circle-0::before {
  background: rgb(221, 221, 221);
}

.circle-1::before {
  background: linear-gradient(
    250deg,
    #7b9fe4 0% 33.33%,
    rgb(221, 221, 221) 33.33% 100%
  );
}

.circle-2::before {
  background: linear-gradient(
    250deg,
    #7b9fe4 0% 33.33%,
    #a762f6 33.33% 66.66%,
    rgb(221, 221, 221) 66.66% 100%
  );
}

.circle-3::before {
  background: linear-gradient(
    250deg,
    #7b9fe4 0% 33.33%,
    #a762f6 33.33% 66.66%,
    #f57dc6 66.66% 100%
  );
}

.day-number {
  font-size: 16px;
}

/* Hide scrollbar on mobile devices */
@media screen and (max-width: 572px) {
  .horizontal-scroll-calendar::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
