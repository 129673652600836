.daily-queries-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  width: 150px;
  height: 100px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.daily-limit-text {
  margin-top: 5px;
  text-align: center;
  color: red;
  font-size: 0.8rem;
}
