.skewed-background,
.skewed-background-opposite {
  position: relative;
  overflow: hidden;
}

.skewed-background::before,
.skewed-background-opposite::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #ccdefb 0%, #808cde 100%);
  z-index: -1;
}

.skewed-background::before {
  transform: skewX(40deg);
  transform-origin: top left;
}

.skewed-background-opposite::before {
  transform: skewX(-40deg);
  transform-origin: top right;
}

/* Add media query for the xs screen size */
@media (max-width: 599px) {
  .skewed-background::before,
  .skewed-background-opposite::before {
    transform: none; /* Remove the skew */
    bottom: 25%; /* Change the height of the element */
  }
}
